@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;

	@include section-padding;
}

.wrapper {
	max-width: $max-width;
	margin-inline: auto;
}

.header {
	h2 {
		max-width: 14ch;
	}

	p {
		color: $text-grey-dark;

		max-width: 40ch;
	}

	margin-bottom: 6rem;

	@include sm-down {
		text-align: center;

		h2 {
			margin-inline: auto;
		}

		p {
			margin-inline: auto;
		}

		margin-bottom: 2rem;
	}
}

.notification_container {
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		border-radius: 1rem;

		box-shadow: 0px 12.66px 27.318px rgba(0, 0, 0, 0.06), 0px 49.973px 49.973px rgba(0, 0, 0, 0.06), 0px 113.272px 67.963px rgba(0, 0, 0, 0.03), 0px 201.224px 80.623px rgba(0, 0, 0, 0.01),
			0px 313.829px 87.952px rgba(0, 0, 0, 0);
	}
}

.grid {
	display: flex;
	flex-direction: column;
	padding: 6rem;
	gap: 10rem;

	margin: 0;

	list-style: none;

	li {
		&:nth-child(odd) {
			.card_header {
				grid-column: 2;
				grid-row: 1;
			}

			.card_component {
				margin-right: 6rem;
				grid-row: 1;
			}
		}

		&:nth-child(even) {
			.card_header {
				grid-column: 1;
				grid-row: 1;
			}

			.card_component {
				margin-left: 6rem;
				grid-row: 1;
			}
		}
	}

	@include sm-down {
		gap: 10rem;
		padding: 4rem 0;

		li {
			&:nth-child(odd) {
				.card_header {
					grid-column: 1;
					grid-row: 1;
				}

				.card_component {
					grid-row: 2;
					grid-column: 1;
					margin-right: 0;
					margin-left: 15vw;
				}
			}

			&:nth-child(even) {
				.card_header {
					grid-column: 1;
					grid-row: 1;
				}

				.card_component {
					grid-row: 2;
					grid-column: 1;
					margin-left: 0;
					margin-right: 15vw;
				}
			}
		}
	}
}

.grid_card {
	margin-inline: auto;
	display: grid;

	grid-template-columns: repeat(2, 1fr);

	align-items: center;

	@include sm-down {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(2, auto);
		row-gap: 2rem;
	}
}

.card_component {
	&:hover {
		transform: scale(1.05);
		filter: drop-shadow(-2.252px 10.132px 21.39px rgba(0, 0, 0, 0.1)) drop-shadow(-7.88px 38.276px 39.402px rgba(0, 0, 0, 0.09)) drop-shadow(-19.138px 86.684px 52.911px rgba(0, 0, 0, 0.05))
			drop-shadow(-33.773px 153.104px 63.043px rgba(0, 0, 0, 0.01)) drop-shadow(-51.785px 239.788px 68.672px rgba(0, 0, 0, 0));

		svg {
			transform: scale(1.1);
			filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.25));
		}
	}

	transition: all 0.3s ease-out;

	svg {
		transition: all 0.3s 0.075s ease-out;
	}
}

.card_eyebrow {
	color: rgba(0, 0, 0, 0.25);
}

.card_title {
	max-width: 19ch;
}

.card_description {
	max-width: 32ch;

	color: $text-grey-dark;
}

.blue_bed_container {
	position: relative;

	img {
		border-radius: 0.875rem;
		max-width: 27rem;

		aspect-ratio: 24/28;
	}

	svg {
		position: absolute;
		bottom: -13%;
		left: -13%;
	}
}

.blue_bed_container_2 {
	position: relative;

	img {
		border-radius: 0.875rem;
		max-width: 27rem;

		aspect-ratio: 24/28;
	}

	svg {
		position: absolute;
		bottom: -20%;
		left: -20%;
	}

	@include sm-down {
		max-width: 27rem;

		svg {
			position: absolute;
			bottom: -20%;
			left: auto;
			right: -20%;
		}
	}
}

.phone_man_container {
	position: relative;

	img {
		border-radius: 0.875rem;
		max-width: 27rem;

		aspect-ratio: 24/28;
	}

	svg {
		position: absolute;
		bottom: -20%;
		right: -20%;
	}

	@include sm-down {
		svg {
			position: absolute;
			bottom: -10%;
			right: auto;
			left: -10%;
		}
	}
}

.dislcaimer {
	margin-top: 2rem;
	color: $text-grey-dark;
}
