@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $white;
	color: $black;

	@include section-padding;
}

.inner_container {
	max-width: $max-width;
	margin-inline: auto;
}

.header {
	grid-area: header;

	p {
		color: #a6a6a6;
	}

	h2 {
		max-width: 19ch;
	}

	margin-bottom: 6rem;

	@include sm-down {
		margin-bottom: 2rem;
	}
}

.quotes {
	grid-area: quotes;

	list-style: none;

	margin: 0;

	display: grid;
	grid-template-columns: repeat(3, 1fr);

	@include sm-down {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, auto);
	}
}

.quote {
	border-top: 1px solid #a6a6a6;
	padding-top: 6rem;

	@include sm-down {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}

.quote .tag {
	display: inline-block;
	padding: 4px 10px;

	color: #000;

	border: none;

	border-radius: 6px;
	background: #f1f2f4;
}

.quote .author {
}

.quote .content {
	max-width: 30ch;
}

.cta {
	grid-area: cta;
	justify-self: end;
	align-self: end;

	@include sm-down {
		justify-self: center;
	}
}

.stars {
	margin: 1.5rem 0;
}
